<template>
  <div class="container">
    <!-- <navbar /> -->
    <!--limit: Maximum number of uploads，on-exceed：Operation when the maximum upload quantity is exceeded  -->
    <div :style="{ display: 'inline-block' }">
      <el-button @click="getProducts()" size="small" type="primary"
        >Packages</el-button
      >
      <input
        :style="{
          width: '250px',
          height: '40px',
          border: '1px solid #DCDFE6',
          padding: '0 15px',
          margin: '10px',
        }"
        type="search"
        placeholder="Enter to Search"
        v-model="pkgName"
      />
      <el-button @click="getProductById(pkgName)" size="small"
        >Search</el-button
      >
      <el-button @click="savePackage()" size="small" type="primary"
        >Post</el-button
      >
    </div>
    <br />
    <br />

    <el-table :data="items" border size="small" style="width: 100%" stripe>
      <el-table-column
        prop="Action"
        label="Action"
        align="center"
        :min-width="160"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="EditRow(scope.$index)"
            >Edit</el-button
          >

          <el-button type="danger" size="small" @click="DelRow(scope.$index)"
            >Delete</el-button
          >
        </template>
      </el-table-column>

      <el-table-column
        v-for="info in rightHeader"
        :key="info.key"
        :property="info.key"
        :label="info.label"
        align="center"
        :min-width="200"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row[scope.column.property]"
            :disabled="scope.row.edited"
          >
          </el-input>
          <!-- Render the contents of the corresponding table {{scope.row[scope.column.property]}} -->
        </template>
      </el-table-column>
    </el-table>

    <el-upload
      class="upload-demo"
      action=""
      :on-change="handleChange"
      :on-remove="handleRemove"
      :on-exceed="handleExceed"
      :limit="limitUpload"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      :auto-upload="false"
    >
      <br />
      <el-button size="small" type="primary">Select Excel File</el-button>

      <!-- <div slot="tip" class="el-upload__tip">Can only upload xlsx / xls document</div> -->
    </el-upload>

    <el-tag> No of records = {{ this.dalen }} </el-tag>

    <el-button @click="savePackage()" size="small" type="primary"
      >Post</el-button
    >

    <!-- Data Display -->
    <el-main>
      <el-table :data="da" size="small" border="">
        <el-table-column :min-width="100" prop="PckgId" label="PckgId">
        </el-table-column>
        <el-table-column :min-width="150" prop="PckgName" label="PckgName">
        </el-table-column>
        <el-table-column :min-width="150" prop="PckgImg" label="PckgImg">
        </el-table-column>
        <el-table-column :min-width="100" prop="PckgRating" label="PckgRating">
        </el-table-column>
        <el-table-column :min-width="300" prop="PckgSub" label="PckgSub">
        </el-table-column>
        <el-table-column
          :min-width="100"
          prop="PckgDuration"
          label="PckgDuration"
        >
        </el-table-column>
        <el-table-column
          :min-width="110"
          prop="TransferOption"
          label="TransferOption"
        >
        </el-table-column>
        <el-table-column :min-width="100" prop="PickUpDrop" label="PickUpDrop">
        </el-table-column>
        <el-table-column :min-width="100" prop="PckdDesc" label="PckdDesc">
        </el-table-column>
        <el-table-column
          :min-width="130"
          prop="PckgPriceAEDAD"
          label="PckgPriceAEDAD"
        >
        </el-table-column>
        <el-table-column
          :min-width="130"
          prop="PckgPriceAEDCH"
          label="PckgPriceAEDCH"
        >
        </el-table-column>
        <el-table-column
          :min-width="110"
          prop="STransferPrice"
          label="STransferPrice"
        >
        </el-table-column>
        <el-table-column :min-width="100" prop="PTransfer1" label="PTransfer1">
        </el-table-column>
        <el-table-column :min-width="100" prop="PTransfer2" label="PTransfer2">
        </el-table-column>
        <el-table-column :min-width="100" prop="PTransfer3" label="PTransfer3">
        </el-table-column>
        <el-table-column :min-width="100" prop="PTransfer4" label="PTransfer4">
        </el-table-column>
        <el-table-column :min-width="100" prop="PTransfer5" label="PTransfer5">
        </el-table-column>
        <el-table-column :min-width="100" prop="PTransfer6" label="PTransfer6">
        </el-table-column>
        <el-table-column :min-width="100" prop="PTransfer7" label="PTransfer7">
        </el-table-column>
        <el-table-column :min-width="100" prop="TourDate" label="TourDate">
        </el-table-column>
        <el-table-column :min-width="100" prop="Infant" label="Infant">
        </el-table-column>
        <el-table-column
          :min-width="130"
          prop="PckgPriceSARAD"
          label="PckgPriceSARAD"
        >
        </el-table-column>
        <el-table-column
          :min-width="130"
          prop="PckgPriceSARCH"
          label="PckgPriceSARCH"
        >
        </el-table-column>
        <el-table-column
          :min-width="120"
          prop="PckgPriceIDRAD"
          label="PckgPriceIDRAD"
        >
        </el-table-column>
        <el-table-column
          :min-width="120"
          prop="PckgPriceIDRCH"
          label="PckgPriceIDRCH"
        >
        </el-table-column>
        <el-table-column
          :min-width="120"
          prop="PckgPriceINRAD"
          label="PckgPriceINRAD"
        >
        </el-table-column>
        <el-table-column
          :min-width="120"
          prop="PckgPriceINRCH"
          label="PckgPriceINRCH"
        >
        </el-table-column>
        <el-table-column
          :min-width="130"
          prop="PckgPriceUSDAD"
          label="PckgPriceUSDAD"
        >
        </el-table-column>
        <el-table-column
          :min-width="130"
          prop="PckgPriceUSDCH"
          label="PckgPriceUSDCH"
        >
        </el-table-column>
        <el-table-column
          :min-width="130"
          prop="STrnsferSARAD"
          label="STrnsferSARAD"
        >
        </el-table-column>
        <el-table-column
          :min-width="110"
          prop="STrnsferIDRAD"
          label="STrnsferIDRAD"
        >
        </el-table-column>
        <el-table-column
          :min-width="110"
          prop="STrnsferINRAD"
          label="STrnsferINRAD"
        >
        </el-table-column>
        <el-table-column
          :min-width="120"
          prop="STrnsferUSDAD"
          label="STrnsferUSDAD"
        >
        </el-table-column>
        <el-table-column :min-width="100" prop="ParentId" label="ParentId">
        </el-table-column>
        <el-table-column :min-width="100" prop="Priority" label="Priority">
        </el-table-column>
      </el-table>
    </el-main>

    <!-- <div v-if="dalen>0" >
        <div v-for="(value, key) in da" :key="key" style="float: left;width: 30%;">
            <p>Numbering:{{value.code}}</p>
            <p>Name:{{value.name}}</p>
            <p>province:{{value.pro}}</p>
            <p>City:{{value.cit}}</p>
            <p>District:{{value.dis}}</p>   
        </div>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
// import Navbar from "../Navbar.vue";
export default {
  // components: { Navbar },
  data() {
    return {
      rightHeader: [
        {
          label: "PckgId",
          key: "PckgId",
        },
        {
          label: "PckgName",
          key: "PckgName",
        },
        {
          label: "PckgImg",
          key: "PckgImg",
        },
        {
          label: "PckgRating",
          key: "PckgRating",
        },
        {
          label: "PckgSub",
          key: "PckgSub",
        },
        {
          label: "PckgDuration",
          key: "PckgDuration",
        },
        {
          label: "TransferOption",
          key: "TransferOption",
        },
        {
          label: "PickUpDrop",
          key: "PickUpDrop",
        },
        {
          label: "PckdDesc",
          key: "PckdDesc",
        },
        {
          label: "PckgPriceAEDAD",
          key: "PckgPriceAEDAD",
        },
        {
          label: "PckgPriceAEDCH",
          key: "PckgPriceAEDCH",
        },
        {
          label: "STransferPrice",
          key: "STransferPrice",
        },
        {
          label: "PTransfer1",
          key: "PTransfer1",
        },
        {
          label: "PTransfer2",
          key: "PTransfer2",
        },
        {
          label: "PTransfer3",
          key: "PTransfer3",
        },
        {
          label: "PTransfer4",
          key: "PTransfer4",
        },
        {
          label: "PTransfer5",
          key: "PTransfer5",
        },
        {
          label: "PTransfer6",
          key: "PTransfer6",
        },
        {
          label: "PTransfer7",
          key: "PTransfer7",
        },
        {
          label: "TourDate",
          key: "TourDate",
        },
        {
          label: "Infant",
          key: "Infant",
        },
        {
          label: "PckgPriceSARAD",
          key: "PckgPriceSARAD",
        },
        {
          label: "PckgPriceSARCH",
          key: "PckgPriceSARCH",
        },
        {
          label: "PckgPriceIDRAD",
          key: "PckgPriceIDRAD",
        },
        {
          label: "PckgPriceIDRCH",
          key: "PckgPriceIDRCH",
        },
        {
          label: "PckgPriceINRAD",
          key: "PckgPriceINRAD",
        },
        {
          label: "PckgPriceINRCH",
          key: "PckgPriceINRCH",
        },
        {
          label: "PckgPriceUSDAD",
          key: "PckgPriceUSDAD",
        },
        {
          label: "PckgPckgPriceUSDCH",
          key: "PckgPckgPriceUSDCH",
        },
        {
          label: "STrnsferSARAD",
          key: "STrnsferSARAD",
        },
        {
          label: "STrnsferIDRAD",
          key: "STrnsferIDRAD",
        },
        {
          label: "STrnsferINRAD",
          key: "STrnsferINRAD",
        },
        {
          label: "STrnsferUSDAD",
          key: "STrnsferUSDAD",
        },
        {
          label: "ParentId",
          key: "ParentId",
        },
        {
          label: "Priority",
          key: "Priority",
        },
      ],
      pkgName: "",
      PckgName: "",
      pckgId: "",
      items: [],
      limitUpload: 1,
      fileTemp: null,
      file: null,
      da: [],
      dalen: 0,
    };
  },
  created() {
    //this.getProducts();
  },
  methods: {
    async getProducts() {
      try {
        const response = await axios.get(
          `https://www.desertlinktours.com/api/PckgName`
        );
        this.items = response.data;
      } catch (err) {
        console.log(err);
      }
    },
    async getProductById(pkgName) {
      try {
        const response = await axios.get(
          `https://www.desertlinktours.com/api/PckgName/${pkgName}`
        );
        this.items = response.data;
        //this.PckgId = response.data.PckgId;
        //this.PckgName = response.data.PckgName;
      } catch (err) {
        console.log(err);
      }
    },
    async savePackage() {
      this.del_all_Package();
      let vm = this;
      // console.log(this.da);
      try {
        await axios.post("https://www.desertlinktours.com/api/Package", {
          product_name: vm.da,
          len: vm.dalen,
          // this is empty object when sent
        });

        // await axios.post("http://localhost:5000/api/Package", {body: {product_name: vm.da, len: vm.dalen}})

        this.$message({
          type: "success",
          message: "Total No of records Posted = " + this.dalen,
        });
        //this.$alert("Total No of records Posted = "+this.dalen,"Message",'success');

        //console.log()
        //this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
    async delPackage(v) {
      try {
        await axios.post("https://www.desertlinktours.com/api/del", {
          del_id: v,
        });
        this.getProducts();
        //console.log()
        //this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
    async del_all_Package() {
      try {
        await axios.post("https://www.desertlinktours.com/api/delall", {});
        //console.log()
        //this.$router.push("/");
      } catch (err) {
        console.log(err);
      }
    },
    DelRow(index) {
      this.delPackage(this.items[index].PckgId);
    },
    handleChange(file, fileList) {
      this.fileTemp = file.raw;

      if (this.fileTemp) {
        if (
          this.fileTemp.type ==
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          this.fileTemp.type == "application/vnd.ms-excel"
        ) {
          this.importfxx(this.fileTemp);
        } else {
          this.$message({
            type: "warning",
            message:
              "The format of the attachment is wrong, please delete it and upload it again!",
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "Please upload an attachment!",
        });
      }
    },
    handleExceed() {
      this.$message({
        type: "warning",
        message: "Exceeded the limit of the maximum number of uploaded files",
      });
      return;
    },
    handleRemove(file, fileList) {
      this.fileTemp = null;
    },
    importfxx(obj) {
      let _this = this;
      let inputDOM = this.$refs.inputer;
      // Get file data through DOM

      this.file = event.currentTarget.files[0];

      var rABS = false; //Whether to read the file as a binary string
      var f = this.file;

      var reader = new FileReader();
      //if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var rABS = false; //Whether to read the file as a binary string
        var pt = this;
        var wb; //Read the completed data
        var outdata;
        var reader = new FileReader();
        reader.onload = function (e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          var XLSX = require("xlsx");
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              //Manual conversion
              type: "base64",
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary",
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); //outdata Is what you want
          //   console.log('The unprocessed raw data is as follows:');
          //   console.log(outdata);
          //Data can be processed here
          let arr = [];
          outdata.map((v) => {
            let obj = {};
            obj.PckgId = v["PckgId"];
            obj.PckgName = v["PckgName"];
            obj.PckgImg = v["PckgImg"];
            obj.PckgRating = v["PckgRating"];
            obj.PckgSub = v["PckgSub"];
            obj.PckgDuration = v["PckgDuration"];
            obj.TransferOption = v["TransferOption"];
            obj.PickUpDrop = v["PickUpDrop"];
            obj.PckdDesc = v["PckdDesc"];
            obj.PckgPriceAEDAD = v["PckgPriceAEDAD"];
            obj.PckgPriceAEDCH = v["PckgPriceAEDCH"];
            obj.STransferPrice = v["STransferPrice"];
            obj.PTransfer1 = v["PTransfer1"];
            obj.PTransfer2 = v["PTransfer2"];
            obj.PTransfer3 = v["PTransfer3"];
            obj.PTransfer4 = v["PTransfer4"];
            obj.PTransfer5 = v["PTransfer5"];
            obj.PTransfer6 = v["PTransfer6"];
            obj.PTransfer7 = v["PTransfer7"];
            obj.TourDate = v["TourDate"];
            obj.Infant = v["Infant"];
            obj.PckgPriceSARAD = v["PckgPriceSARAD"];
            obj.PckgPriceSARCH = v["PckgPriceSARCH"];
            obj.PckgPriceIDRAD = v["PckgPriceIDRAD"];
            obj.PckgPriceIDRCH = v["PckgPriceIDRCH"];
            obj.PckgPriceINRAD = v["PckgPriceINRAD"];
            obj.PckgPriceINRCH = v["PckgPriceINRCH"];
            obj.PckgPriceUSDAD = v["PckgPriceUSDAD"];
            obj.PckgPriceUSDCH = v["PckgPriceUSDCH"];
            obj.STrnsferSARAD = v["STrnsferSARAD"];
            obj.STrnsferIDRAD = v["STrnsferIDRAD"];
            obj.STrnsferINRAD = v["STrnsferINRAD"];
            obj.STrnsferUSDAD = v["STrnsferUSDAD"];
            obj.ParentId = v["ParentId"];
            obj.Priority = v["Priority"];
            arr.push(obj);
          });
          _this.da = arr;
          _this.dalen = arr.length;
          return arr;
        };
        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
  },
};
</script>

<style>
/*Modify Table background color and text colors*/
.el-table da,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  border-color: #dbfdf1;
  background-color: #66d9ff;
  color: black;
}
/*Modify the highlight of the Element-UI's Table in the mouse hover HOVER*/
.el-table tbody tr:hover > td {
  background-color: #e6fff8 !important;
}
.el-button {
  font-weight: bold;
}
</style>
